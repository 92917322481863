<template>
    <section class="user-details">
        <tool-bar>
            Details of employee Test, Monthly
        </tool-bar>
        <div style="padding: 1rem; ">
            <div class="columns">
                <div class="column is-4">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title">
                                📈Monthly Sales Ranking
                            </p>
                        </header>
                        <div class="card-content">
                            <div class="content is-large has-text-centered">
                                <h6 class="has-text-weight-normal"
                                    style="margin-bottom: 0.5rem; ">
                                    Ranked
                                </h6>
                                <h1 class="is-marginless">
                                    🥇1st
                                </h1>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item">
                                🏅Set&nbsp;<strong>Ranking</strong>
                            </a>
                        </footer>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title">
                                🤑Prepayment sold
                            </p>
                        </header>
                        <div class="card-content">
                            <div class="content is-large has-text-centered">
                                <h6 class="has-text-weight-normal"
                                    style="margin-bottom: 0.5rem; ">
                                    Totaling
                                </h6>
                                <h1 class="is-marginless">
                                    <small>HK$</small>5,000
                                </h1>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item">
                                🏅Set&nbsp;<strong>Prepayment</strong>
                            </a>
                        </footer>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title">
                                💰Value since Employment
                            </p>
                        </header>
                        <div class="card-content">
                            <div class="content is-large has-text-centered">
                                <h6 class="has-text-weight-normal"
                                    style="margin-bottom: 0.5rem; ">
                                    Generated
                                </h6>
                                <h1 class="is-marginless">
                                    <small>HK$</small>100,000
                                </h1>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item">
                                🏅Set&nbsp;<strong>Value</strong>
                            </a>
                        </footer>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-full">
                    <div class="card">
                        <div class="card-header is-flex is-justify-content-space-between is-align-items-center">
                            <p class="card-header-title">
                                Shopping Records
                            </p>
                            <div class="tags are-medium is-marginless"
                                 style="padding-right: 1rem; ">
                                <span class="tag is-marginless">
                                    sharlene@marierskincare.com
                                </span>
                            </div>
                        </div>
                        <div class="card-content">
                            <b-table detailed
                                     detail-key="_id"
                                     v-bind:data="orders">
                                <template>
                                    <b-table-column v-slot="{ row }"
                                                    field="id"
                                                    label="Ordered on"
                                                    centered>
                                        {{ row.createdAt | dateFnsFormatter("d/MMM/yyyy hh:mm a") }}
                                    </b-table-column>
                                    <b-table-column v-slot="{ row }"
                                                    field="count"
                                                    label="Item Count"
                                                    sortable>
                                        {{ row.count }}
                                    </b-table-column>
                                    <b-table-column v-slot="{ row }"
                                                    field="total"
                                                    label="Total"
                                                    sortable>
                                        {{ row.total }}
                                    </b-table-column>
                                    <b-table-column v-slot="{ row }"
                                                    label="Status"
                                                    centered>
                                        {{ row.status }}
                                    </b-table-column>
                                    <b-table-column v-slot="{ row }"
                                                    label=""
                                                    centered>
                                        <router-link class="button is-small is-text"
                                                     v-bind:to="`/shop/orders/${ row._id }`">
                                            <font-awesome-icon icon="edit"></font-awesome-icon>
                                        </router-link>
                                    </b-table-column>
                                </template>

                                <template v-slot:detail="{ row }">
                                    <article class="media">
                                        <div class="media-content">
                                            <div class="content">
                                                <nav class="level">
                                                    <div class="level-item has-text-centered">
                                                        <div>
                                                            <p class="heading">Prepayment</p>
                                                            <p class="title">
                                                                HK$0
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="level-item has-text-centered">
                                                        <div>
                                                            <p class="heading">Spending</p>
                                                            <p class="title">
                                                                HK${{ calculateTotalOrderAmount(orders.filter(({ owner }) => owner === row._id)) + calculateTotalBookingAmount(bookings.filter(({ owner }) => owner === row._id)) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="level-item has-text-centered">
                                                        <div>
                                                            <p class="heading">Orders</p>
                                                            <p class="title">
                                                                {{ orders.filter(({ owner }) => owner === row._id).length }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="level-item has-text-centered">
                                                        <div>
                                                            <p class="heading">Bookings</p>
                                                            <p class="title">
                                                                {{ bookings.filter(({ owner }) => owner === row._id).length }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    </article>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-full">
                    <div class="card">
                        <div class="card-header">
                            <p class="card-header-title">
                                Remuneration
                            </p>
                        </div>
                        <div class="card-content">
                            <div class="table-container">
                                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                                    <thead>
                                    <tr>
                                        <th>Paid on</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            28/11月/2022 09:11 上午
                                        </td>
                                        <td>
                                            Monthly Pay
                                        </td>
                                        <td>
                                            HK$17,500
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <b-field label="Create new record"
                                         grouped>
                                    <b-datepicker placeholder="Click to select..."
                                                  icon="calendar-today"
                                                  trap-focus>
                                    </b-datepicker>
                                    <b-select placeholder="Type">
                                        <option value="1">Monthly Pay</option>
                                        <option value="2">Hourly Pay</option>
                                        <option value="3">Bonus</option>
                                    </b-select>
                                    <b-input placeholder="Amount (HK$)"
                                             type="number"
                                             style="flex: 1; ">
                                    </b-input>
                                    <p class="control">
                                        <b-button class="button is-primary">
                                            Add
                                        </b-button>
                                    </p>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-full">
                    <div class="card">
                        <div class="card-header">
                            <p class="card-header-title">
                                Work Evaluations
                            </p>
                        </div>
                        <div class="card-content">
                            <div class="table-container">
                                <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                                    <thead>
                                    <tr>
                                        <th>Review on</th>
                                        <th>Attachment</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            28/11月/2022 09:11 上午
                                        </td>
                                        <td>
                                            <div class="tags are-medium">
                                                <span class="tag">report_filled.pdf</span>
                                                <span class="tag">hkid_sup.pdf</span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <b-field>
                                    <b-upload multiple drag-drop expanded>
                                        <section class="section">
                                            <div class="content has-text-centered">
                                                <p>
                                                    <b-icon icon="upload" size="is-large"></b-icon>
                                                </p>
                                                <p>Create new entry by dropping your files here or clicking to upload</p>
                                            </div>
                                        </section>
                                    </b-upload>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "EmployeeDetails",
    components: {
        ToolBar: () => import("@/components/layouts/ToolBar.vue"),
    },
    data () {
        return {

        };
    },
    computed: {
        orders () {
            return [
                {
                    createdAt: new Date(2018, 10, 1, 18, 40),
                    count: "1",
                    total: "1,388.00",
                    status: "pending",
                }
            ];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
